import i18n from "@/i18n"

import Moderation from "./Moderation.vue"
import ModerationProducts from "./ModerationProducts"
import Export from "./Export"
import ExportHistory from "./ExportHistory"

import { Attributes, AttributesList, EditAttribute } from "./Attributes/index.js"
import { Types, TypesList, EditType } from "./Types/"
import BrandsPage from "./Brands"
import { UnitsList } from "./Units"

const applicationCode = "PRODCAT1"
const productsComponentCode = "PRODCAT02"
const exportComponentCode = "PM:COMP:EX"
const exportHistoryComponentCode = "PM:COMP:EH"
const typesComponentCode = "PRODCAT4"
const brandsComponentCode = "PRODCAT3"
const unitsComponentCode = "PRODCAT5"
const attributesComponentCode = "PRODCAT7"

export default {
  path: "/moderation",
  component: Moderation,
  meta: {
    permissionType: "application",
    permissionCode: applicationCode,
    code: "PRODCAT1",
    withoutChecking: true,
    layout: "ant-layout"
  },
  children: [
    {
      path: "products",
      component: ModerationProducts,
      meta: {
        permissionType: "component",
        permissionCode: `${applicationCode}/${productsComponentCode}`,
        code: productsComponentCode,
        layout: "ant-layout",
        title: i18n.t("moderationOfProducts")
      }
    },
    {
      path: "export",
      component: Export,
      meta: {
        permissionType: "component",
        permissionCode: `${applicationCode}/${exportComponentCode}`,
        code: exportComponentCode,
        layout: "ant-layout",
        title: i18n.t("exportPageTitle")
      }
    },
    {
      path: "export-history",
      component: ExportHistory,
      meta: {
        permissionType: "component",
        permissionCode: `${applicationCode}/${exportHistoryComponentCode}`,
        code: exportHistoryComponentCode,
        layout: "ant-layout",
        title: i18n.t("exportHistoryTitle")
      }
    },
    {
      path: "types",
      component: Types,
      children: [
        {
          path: "",
          component: TypesList,
          name: "TypesList",
          meta: {
            permissionType: "component",
            permissionCode: `${applicationCode}/${typesComponentCode}`,
            code: "PRODCAT4",
            layout: "ant-layout",
            title: i18n.t("types")
          }
        },
        {
          path: ":code",
          component: EditType,
          name: "EditType",
          meta: {
            permissionType: "component",
            permissionCode: `${applicationCode}/${typesComponentCode}`,
            code: "PRODCAT4",
            layout: "ant-layout",
            title: i18n.t("types")
          }
        }
      ]
    },
    {
      path: "attributes",
      component: Attributes,
      meta: {
        permissionType: "component",
        permissionCode: `${applicationCode}/${attributesComponentCode}`,
        code: attributesComponentCode,
        layout: "ant-layout"
      },

      children: [
        {
          path: "",
          name: "AttributeList",
          component: AttributesList,
          meta: {
            permissionType: "component",
            permissionCode: `${applicationCode}/${attributesComponentCode}`,
            code: attributesComponentCode,
            title: i18n.t("attributes"),
            layout: "ant-layout"
          }
        },
        {
          path: "edit/:attributeCode",
          name: "EditAttribute",
          component: EditAttribute,
          meta: {
            permissionType: "component",
            permissionCode: `${applicationCode}/${attributesComponentCode}`,
            code: attributesComponentCode,
            title: i18n.t("editAttributes"),
            layout: "ant-layout"
          }
        }
      ]
    },
    {
      path: "brands",
      component: BrandsPage,
      meta: {
        permissionType: "component",
        permissionCode: `${applicationCode}/${brandsComponentCode}`,
        code: brandsComponentCode,
        layout: "ant-layout",
        title: i18n.t("brands")
      }
    },
    {
      path: "units",
      name: "UnitsList",
      component: UnitsList,
      meta: {
        permissionType: "component",
        permissionCode: `${applicationCode}/${unitsComponentCode}`,
        code: unitsComponentCode,
        title: i18n.t("units"),
        layout: "ant-layout"
      }
    }
  ]
}
